<template>
  <ValidationProvider
    v-slot="{ failed, errors }"
    :rules="rules"
    :name="name"
    :vid="vid"
  >
    <div class="form-group" :style="{ 'margin-bottom': marginBottom }">
      <label v-if="label" class="form-control-label">
        {{ label }}
        <feather
          v-if="explain"
          style="vertical-align:bottom;margin-left:10px;cursor:pointer;"
          type="help-circle"
          @click="handleExplain"
        />
        <span v-if="sublabel" class="text-danger">{{ sublabel }}</span>
      </label>
      <div v-if="!hideInput" class="input-group input-group-merge">
        <div v-if="linkstart" class="mr-1 mt-auto mb-auto">
          {{linkstart}}
        </div>
        <div v-else-if="icon" class="input-group-prepend">
          <span class="input-group-text">
            <feather :type="icon" class="align-middle icon-dual"></feather>
          </span>
        </div>
        <input
          v-if="type === 'number'"
          ref="input"
          v-model="iValue"
          step="any"
          :name="name"
          :placeholder="placeholder"
          :type="type"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          class="form-control"
          :class="{ 'is-invalid': failed }"
          :accept="accept"
          inputmode="numeric"
          pattern="[0-9]*"
          @change="handleChanged"
        />
        <input
          v-else
          ref="input"
          v-model="iValue"
          :name="name"
          :placeholder="placeholder"
          :type="type"
          :readonly="readonly"
          :disabled="disabled"
          :autocomplete="autocomplete"
          class="form-control"
          :class="{ 'is-invalid': failed }"
          :accept="accept"
          @change="handleChanged"
        />
        <slot></slot>
        <b-form-invalid-feedback
          v-if="errors.length"
          :style="{ marginLeft: icon ? '48px' : '' }"
        >
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </div>
      <span v-if="help" class="help-block">
        <small>{{ help }}</small>
      </span>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [String, Number],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    linkstart:{
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    hideInput: {
      type: [String, Boolean],
      default: false,
    },
    explain: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: String,
      default: '1rem',
    },
  },
  data() {
    return {
      iValue: '',
    }
  },
  computed: {

  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.iValue) {
        this.iValue = newVal;
      }
    },
    iValue: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.type !== 'file') {
          if (this.rules && this.rules.match(/(email|customurl|subdomain)/)) {
            if (newVal)
              newVal = newVal.replace(/\s/, '')
          }

          this.$emit('input', newVal)
        }
      }
    }
  },
  mounted() {
    this.iValue = this.value
  },
  methods: {
    handleChanged() {
      if (this.type === 'file' && this.$refs.input.files.length) {
        this.$emit('changed', this.$refs.input.files[0])
      } else {
        this.$emit('changed', this.iValue)
      }
    },

    handleExplain() {
      this.$emit('clickExplain', true)
    },
  },
}
</script>
