import lazyLoading from './lazyLoading'
import AuthLayout from '@layouts/Auth'
import AuthGuard from './guards/auth-guard'

export default [
  {
    path: '',
    component: AuthLayout,
    beforeEnter: AuthGuard,
    children: [
      {
        name: 'login',
        path: 'login',
        component: lazyLoading('Auth/Login'),
      },
      {
        name: 'passwords.request',
        path: 'passwords/request',
        component: lazyLoading('Auth/ForgotPassword'),
      },
      {
        name: 'passwords.reset',
        path: 'passwords/reset',
        component: lazyLoading('Auth/ChangePassword'),
      }
    ],
  },
]
