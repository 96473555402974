import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  index: {
    customers: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'active',
      prospect_status:'all',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
  archived: {
    businesses: null,
    total: 0,
    queries: {
      per_page: 10,
      page: 1,
      status: 'archived',
      search: null,
      sort_by: 'id',
      sort_desc: false,
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { page, total, queries, customers }) {
      state[page] = {
        total: total,
        queries: queries,
        customers:customers
      }
    },

    updateBroadcastersSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    currentPage(state, page) {
      state.currentPage = page
    },
  },

  actions: {
    getAll({ commit }, { queries, page }) {
      queries.only_customers = true;
      return new Promise((resolve, reject) => {
        axios
            .get('/customers', { params: queries })
            .then((res) => {
              commit('getAllSuccess', {
                page: page,
                total: res.data.meta.total,
                queries: queries,
                customers: res.data.data,
              })
              resolve(true)
            })
            .catch((error) => {
              reject(error)
            })
      })
    },

    find({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/${businessId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    delete({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/businesses/${businessId}`)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    setCurrentPage({ commit }, page) {
      commit('currentPage', page)
    },

  },

  getters: {
    all(state) {
      return state[state.currentPage].customers
    },

    queries(state) {
      return state[state.currentPage].queries
    },

    total(state) {
      return state[state.currentPage].total
    },
  },
}
