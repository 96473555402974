<template>
  <div id="wrapper" :class="{ 'has-notification': hasNotification }">
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="top-notification-bar"
      :class="{
        'danger text-white': notificationData['status'] === 'danger',
        'warning': notificationData['status'] === 'warning',
      }"
      v-html="notificationData['msg']"
    ></div>
    <!--eslint-enable-->
    <Topbar v-show="!blockedScreen" :is-menu-opened="showMobileMenu" />
    <SideBar v-show="!blockedScreen" v-if="user" />
    <div class="content-page" :class="{ 'blocked-screen': blockedScreen }">
      <div class="content" style="margin-top:10px;">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from './Partials/Topbar'
import SideBar from './Partials/SideBar'

export default {
  components: { Topbar, SideBar },
  metaInfo () {
    return {
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: 'https://rengine.sfo3.cdn.digitaloceanspaces.com/favicon.png' , type: 'image/x-icon' }
      ]
    }
  },
  data() {
    return {
      showMobileMenu: false,
    }
  },
  computed: {
    blockedScreen() {
      return this.$store.getters['auth/blockedScreen']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    hasNotification() {
      return this.user && this.user.role === 'Agency' && this.user.agency.owner.on_trial && this.user.banner_text;
    },

    notificationData() {
      let data = {
        'status': undefined,
        'msg': ''
      }
      if (this.user && this.user.role === 'Agency' && this.user.agency.owner.on_trial)
      {
        data = {
          'status': 'danger',
          'msg': this.user.banner_text ? this.user.banner_text.replace('$$trial_end_date$$',this.user.agency.owner.trial_end_date).replace('$$trial_days_left$$',this.user.agency.owner.trial_days_left) : ''
        }
      }
      return data
    },
  },
  mounted() {
    if(this.user && this.user.role === 'Agency') {
      this.initFreshdesk();
    }
  },
  methods: {
    initFreshdesk(){
      window.fwSettings={
        'widget_id':73000004278
      };
      if (typeof window.FreshworksWidget !== "function") {
        var n = function () {
          n.q.push(arguments)
        };
        n.q = [];
        window.FreshworksWidget = n
      }
      const script = document.createElement('script');
      script.src = 'https://widget.freshworks.com/widgets/73000004278.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    },
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
  },
}
</script>
